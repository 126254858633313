<template>
  <modal :class="'modal-form ' + (editEnabled ? 'manager-form' : ' employer-form')" @exit="$emit('exit')">
    <template v-slot:header>
            <span v-if="editEnabled">
                <span v-if="position.id">Edycja zlecenia</span>
                <span v-else><strong v-if="onDuplicate" class="center">DUPLIKAT<br/></strong>Nowe zlecenie </span>
            </span>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row field-row-wide">
          <div class="field-row">
            <div class="form-group date">
              <label>Zlecono</label>
              <div class="">
                <input-wrapper :error="errors['createdAt']">
                  <datepicker
                      v-if="editEnabled"
                      valueType="YYYY-MM-DD"
                      v-model="position.createdAt"
                      :clearable="false"
                      type="date"
                      format="DD.MM.YYYY"
                  />
                  <date-field :value="position.createdAt" class="date readonly" v-else/>
                </input-wrapper>
              </div>
            </div>
            <div class="form-group width">
              <label>Zakończono</label>
              <div class="">
                <input-wrapper :error="errors['closedAt']">
                  <datepicker
                      v-if="editEnabled"
                      valueType="YYYY-MM-DD"
                      v-model="position.closedAt"
                      :clearable="true"
                      format="DD.MM.YYYY"
                  />
                  <datepicker
                      v-else-if="assigned && (position.status === 8 || position.status === 2)"
                      valueType="YYYY-MM-DD"
                      v-model="confirmDate"
                      :clearable="false"
                      type="date"
                      format="DD.MM.YYYY"
                  />
                  <date-field
                      v-else
                      :value="position.closedAt"
                      class="date readonly"
                  />
                </input-wrapper>
              </div>
              <span v-if="editEnabled">
                <span class="button" @click="position.closedAt = position.createdAt">
                  data zlecenia
                </span>
              </span>
            </div>
          </div>
          <div class="field-row">
            <div class="form-group task">
              <label>Zadanie</label>
              <div class="form-control">
                <input-wrapper :error="errors.taskDefinition">
                  <v-select append-to-body :options="taskDefinitions" :clearable="false"
                            v-model="position.taskDefinition" label="name" v-if="editEnabled"></v-select>
                  <span v-else class="readonly">{{ position.taskDefinition ? position.taskDefinition.name : '' }}</span>
                </input-wrapper>
              </div>
            </div>
            <div class="form-group quantity">
              <label v-tooltip="'Zlecona powierzchnia'">Pow. zlecona</label>
              <div class="form-control small-number">
                <input-wrapper :error="errors.scheduledCultivatedArea">
                  <field-unit unit="ha" class="text" v-model="position.scheduledCultivatedArea" v-if="editEnabled"/>
                  <unit unit="ha" :value="position.scheduledCultivatedArea" class="readonly num-left" v-else/>
                </input-wrapper>
              </div>
              <unit :value="false" unit="ha" class="unit" v-if="editEnabled"/>
            </div>
          </div>

          <div class="field-row">
            <div class="form-group field">
              <label>Pole</label>
              <div class="form-control">
                <input-wrapper :error="errors.field">
                  <v-select append-to-body :options="dictionaries.fields" :clearable="false" v-model="position.field"
                            label="name" v-if="editEnabled"></v-select>
                  <span class="readonly" v-else-if="position.field">{{ position.field.name }}</span>
                </input-wrapper>
              </div>
            </div>
            <div class="form-group quantity">
              <label v-tooltip="'Wykonane powierzchnia'">Pow. wykonana</label>
              <div class="form-control small-number">
                <input-wrapper :error="errors.realizedCultivatedArea">
                  <field-unit unit="ha" class="text" v-model="position.realizedCultivatedArea" v-if="editEnabled"/>
                  <unit unit="ha" :value="position.realizedCultivatedArea" class="readonly num-left" v-else/>
                </input-wrapper>
              </div>
              <unit :value="false" unit="ha" class="unit" v-if="editEnabled"/>
              <span class="unit" v-if="editEnabled"></span>
              <span v-if="editEnabled">
                <span
                    v-if="editEnabled"
                    class="button"
                    @click="position.realizedCultivatedArea = position.scheduledCultivatedArea"
                >
                  wyk. całość
                </span>
              </span>
            </div>

          </div>
          <div class="form-group description">
            <label>Komentarz</label>
            <div class="form-control">
              <textarea-autosize v-model="position.comments" v-if="editEnabled"></textarea-autosize>
              <span class="comments" v-else>{{ position.comments }}</span>
            </div>
          </div>
          <div class="form-group description">
            <label>Komentarz pracownika</label>
            <div class="form-control">
              <textarea-autosize v-model="position.employerComments" v-if="assigned || editEnabled"></textarea-autosize>
              <span class="comments" v-else>{{ position.comments }}</span>
            </div>
          </div>
          <div class="field-row" v-if="isEnabled('fertilizer')">
            <div class="form-group fertilizer min50">
              <label>
                Nawóz
                <span class="button button-ico" v-tooltip="'wybierz'" @click="select = 'fertilizer'" v-if="editEnabled"><i
                    class="ico ico-search"></i></span>
              </label>
              <div class="form-control selector">
                <span class="desc">
                  <span
                      v-if="position.fertilizer"
                      v-tooltip="position.fertilizer.description"
                  >
                    {{ position.fertilizer.name }}:
                    <span class="composition" v-if="editEnabled">
                      <span v-if="position.fertilizer.composition.n">
                        <strong>N</strong>
                        <span class="input-value">
                          {{ $n(position.fertilizer.composition.n, 'simple') }}
                        </span>
                      </span>
                      <span v-if="position.fertilizer.composition.p">
                        <strong>P<sub>2</sub>O<sub>5</sub></strong>
                        <span class="input-value">
                          {{ $n(position.fertilizer.composition.p, 'simple') }}
                        </span>
                      </span>
                      <span v-if="position.fertilizer.composition.b">
                        <strong>K<sub>2</sub>O</strong>
                        <span class="input-value">
                          {{ $n(position.fertilizer.composition.b, 'simple') }}
                        </span>
                      </span>
                      <span v-if="position.fertilizer.composition.ca">
                        <strong>CaO</strong>
                        <span class="input-value">
                          {{ $n(position.fertilizer.composition.ca, 'simple') }}
                        </span>
                      </span>
                      <span v-if="position.fertilizer.composition.mg">
                        <strong>MgO</strong>
                        <span class="input-value">
                          {{ $n(position.fertilizer.composition.mg, 'simple') }}
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span class="btns" v-if="editEnabled">
                  <fertilizer-picker
                      v-if="select === 'fertilizer'"
                      @rowclick="position.fertilizer = $event, select = null" @exit="select = null"
                      :modal="true"
                      :route="false"
                      :av="false"
                      :params="searchParams"
                  >
                    <template v-slot:header>
                      <h2>Wybierz środek</h2>
                    </template>
                    <template v-slot:footer>
                      <a href="#" @click.stop="select = null" class="button">anuluj <i
                          class="ico ico-close"></i></a>
                    </template>
                  </fertilizer-picker>
                </span>
              </div>
            </div>
            <div class="form-group qty">
              <label>Ilość</label>
              <div class="form-control">
                <input-wrapper :error="errors.fertilizerQuantity">
                  <field-unit v-model="position.fertilizerQuantity"
                              :unit="position.fertilizer ? position.fertilizer.unit : 'l'" v-if="editEnabled"/>
                  <unit :unit="(position.fertilizer ? position.fertilizer.unit : 'l') + '/ha'"
                        :value="position.fertilizerQuantity" class="unit" v-else/>
                </input-wrapper>
              </div>
              <unit :unit="position.fertilizer ? position.fertilizer.unit : 'l'" :value="false" class="unit"
                    v-if="editEnabled"/>
              <span class="unit2" v-if="editEnabled">/ha</span>
            </div>
          </div>
          <div class="form-group fertilizer" v-if="isEnabled('mixture')">
            <label>
              Mieszanina
              <span class="button button-ico" v-tooltip="'wybierz'" @click="select = 'mixture'" v-if="editEnabled"><i
                  class="ico ico-search"></i></span>
            </label>
            <div class="form-control selector">
              <span class="desc">
                <span v-if="position.mixture">
                  {{ position.mixture.name }}
                  <small v-if="position.mixture.description"
                         class="description fertilizer-description-move">
                    <label>Zastosowanie:</label>
                    {{ position.mixture.description }}
                  </small>
                  <div class="grid positions" v-if="position.mixture.positions.length > 0">
                    <div class="head">
                      <div class="name">Nazwa środka</div>
                      <div class="quantity">Ilość na [ha]</div>
                      <div class="quantity quantity-value">Na opryskiwacz <strong><unit
                          :value="position.mixture.surface" :unit="false"/></strong>[ha]
                      </div>
                      <div class="content" v-if="editEnabled">Substancja czynna</div>
                    </div>
                    <div class="row" v-for="(row, index) in position.mixture.positions"
                         :key="'pos'+index">
                      <div class="name">
                        <span v-if="row.plantProtection">
                          {{ row.plantProtection.name }}
                        </span>
                        <span v-if="row.fertilizer">
                          {{ row.fertilizer.name }}
                        </span>
                      </div>
                      <div class="quantity">
                        <span class="simple-row num">
                          <span v-if="row.plantProtection" class="input-value">
                            <unit :unit="row.plantProtection.unit" :value="row.quantity"/>
                          </span>
                          <span v-if="row.fertilizer" class="input-value">
                            <unit :unit="row.fertilizer.unit" :value="row.quantity"/>
                          </span>
                        </span>
                      </div>
                      <div class="quantity  quantity-value">
                        <span class="simple-row num">
                          <span>
                            <unit
                                :unit="row.plantProtection ? row.plantProtection.unit : row.fertilizer.unit"
                                :value="row.quantity * position.mixture.surface"/>
                          </span>
                        </span>
                      </div>
                      <div class="content" v-if="editEnabled">
                        <template v-if="row.fertilizer">
                          <span v-if="row.fertilizer.composition.n">
                            <strong>N</strong>
                            <span class="input-value">
                              {{ $n(row.fertilizer.composition.n, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.fertilizer.composition.p">
                            <strong>P<sub>2</sub>O<sub>5</sub></strong>
                            <span class="input-value">
                              {{ $n(row.fertilizer.composition.p, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.fertilizer.composition.b">
                            <strong>K<sub>2</sub>O</strong>
                            <span class="input-value">
                              {{ $n(row.fertilizer.composition.b, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.fertilizer.composition.ca">
                            <strong>CaO</strong>
                            <span class="input-value">
                              {{ $n(row.fertilizer.composition.ca, 'simple') }}
                            </span>
                          </span>
                          <span v-if="row.fertilizer.composition.mg">
                            <strong>MgO</strong>
                            <span class="input-value">
                              {{ $n(row.fertilizer.composition.mg, 'simple') }}
                            </span>
                          </span>
                        </template>
                        <template v-if="row.plantProtection">
                          <div class="composition">
                            <span v-for="(cnt, ii) in row.plantProtection.contents"
                                  v-bind:key="ii">
                              <strong>{{ cnt.ingredient.name }}</strong> - <unit
                                :value="cnt.quantity" :unit="cnt.unit"/>{{
                                ii < row.plantProtection.contents.length - 1 ? ', ' : ''
                              }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </span>
              </span>
              <span class="btns" v-if="editEnabled">
                <mixture-picker
                    v-if="select === 'mixture'"
                    @rowclick="position.mixture = $event, select = null"
                    @exit="select = null"
                    :modal=true
                    :route=false
                    :av="false"
                    :params="searchParams">
                  <template v-slot:header>
                    <h2>Wybierz mieszaninę</h2>
                  </template>
                  <template v-slot:footer>
                    <a href="#" @click.stop="select = null" class="button">anuluj <i
                        class="ico ico-close"></i></a>
                  </template>
                </mixture-picker>
              </span>
            </div>
          </div>
          <div class="field-row">
            <div class="form-group fertilizer" v-if="isEnabled('crop')">
              <label>
                Roślina
                <span class="button button-ico" v-tooltip="'wybierz'" @click="select = 'crop'" v-if="editEnabled"><i
                    class="ico ico-search"></i></span>
              </label>
              <div class="form-control selector">
                <span class="desc">
                  <span v-if="position.crop" v-tooltip="position.crop.group.name">
                    {{ position.crop.name }}
                  </span>
                </span>
                <span class="btns" v-if="editEnabled">
                  <crop-picker
                      v-if="select === 'crop'"
                      @rowclick="selectCrop($event)"
                      @exit="select = null"
                      :modal=true
                      :route=false
                      :av="false"
                      :params="searchParams"
                  >
                    <template v-slot:header>
                      <h2>Wybierz roślinę</h2>
                    </template>
                    <template v-slot:footer>
                      <a href="#" @click.stop="select = null" class="button">anuluj <i
                          class="ico ico-close"></i></a>
                    </template>
                  </crop-picker>
                </span>
              </div>
            </div>
            <div class="field-row" v-if="isEnabled('label')">
              <div class="form-group fertilizer flex-2">
                <label>
                  Etykieta
                  <span class="button button-ico" v-tooltip="'wybierz'" @click="select = 'label'" v-if="editEnabled"><i
                      class="ico ico-search"></i></span>
                </label>
                <div class="form-control selector">
                  <span class="desc">
                    <span v-if="position.label" v-tooltip="position.label.description">
                      {{ position.label.name }}
                      {{ position.label.crop && !isEnabled('crop') ? ' - ' + position.label.crop.name : '' }}
                    </span>
                  </span>
                  <span class="btns" v-if="editEnabled">
                    <label-picker
                        v-if="select === 'label'"
                        @rowclick="position.label = $event, select = null"
                        :crop="position.crop"
                        @exit="select = null"
                        :modal="true"
                        :route="false"
                        :av="false"
                        :params="searchParams"
                    >
                      <template v-slot:header>
                        <h2>
                          Wybierz etykietę
                          <strong v-if="position.crop">{{ position.crop.name }}</strong>
                        </h2>
                      </template>
                      <template v-slot:footer>
                        <a href="#" @click.stop="select = null" class="button">anuluj <i
                            class="ico ico-close"></i></a>
                      </template>
                    </label-picker>
                  </span>
                </div>
              </div>
              <div class="form-group width flex-1">
                <label>Norma</label>
                <div class="form-control small-number">
                  <input-wrapper :error="errors.labelNorm">
                    <field-unit v-model="position.labelNorm" unit="m" v-if="editEnabled"/>
                    <unit :value="position.labelNorm" class="readonly" :unit="'kg/ha'" v-else/>
                  </input-wrapper>
                </div>
                <span class="unit" v-if="editEnabled">kg/ha</span>
              </div>
            </div>
          </div>
          <div class="field-row" v-if="isEnabled('vehicle') || isEnabled('machine')">
            <div class="form-group vehicle" v-if="isEnabled('vehicle')">
              <label>Pojazd</label>
              <div class="form-control">
                <input-wrapper :error="errors.vehicle">
                  <v-select append-to-body :options="filteredVehicles" :clearable="true" v-model="position.vehicle"
                            label="name" v-if="editEnabled"></v-select>
                  <span class="readonly" v-else-if="position.vehicle">{{ position.vehicle.name }}</span>
                </input-wrapper>
              </div>
            </div>
            <div class="field-row">
              <div class="form-group machine flex-2" v-if="isEnabled('machine')">
                <label>Maszyna</label>
                <div class="form-control">
                  <input-wrapper :error="errors.machine">
                    <v-select append-to-body :options="filteredMachines" :clearable="true" v-model="position.machine"
                              label="name" v-if="editEnabled"></v-select>
                    <span v-else-if="position.machine" class="readonly">{{ position.machine.name }}</span>
                  </input-wrapper>
                </div>
              </div>
              <div class="form-group width flex-1" v-if="isEnabled('machine')">
                <label>Szerokość</label>
                <div class="form-control small-number">
                  <input-wrapper :error="errors.machineWidth">
                    <field-unit v-model="position.machineWidth" unit="m" v-if="editEnabled"/>
                    <unit :value="position.machineWidth" class="readonly" unit="m" v-else/>
                  </input-wrapper>
                </div>
                <unit unit="m" :value="false" class="unit" v-if="editEnabled"/>
              </div>
            </div>
          </div>
          <div class="form-group employer" v-if="editEnabled">
            <label>Pracownik</label>
            <div class="form-control">
              <input-wrapper :error="errors.employer">
                <v-select append-to-body :options="dictionaries.employers" v-model="position.employer"
                          v-if="editEnabled" label="name"></v-select>
                <span class="readonly" v-else-if="position.employer">{{ position.employer.name }}</span>
              </input-wrapper>
            </div>
          </div>
        </div>
      </form>
      <form @submit.prevent="toConfirm = {title: 'Zlecenie wykonano częściowo', handler : toConfirmPartial}">
        <modal v-if="toConfirmPartial">
          <template v-slot:header>
            Częściowa realizacja zlecenia
          </template>
          <template v-slot:body>
            <p>
              Wykonano:
              <field-unit unit="ha" class="text" style="width: 100px;" v-model="partialValue"
                          placeholder="Podaj ilość"/>
            </p>
            <div>
              <br/>
            </div>
          </template>
          <template v-slot:footer>
            <button type="submit" class="button button-danger">zakończ częściowo</button>
            <button type="button" @click.prevent="toConfirmPartial = null" class="button">anuluj</button>
          </template>
        </modal>
      </form>
      <confirm v-if="remove" @close="remove=false" @ok="onRemove"></confirm>
      <confirm v-if="toConfirm" @close="toConfirm=null" @ok="toConfirm.handler">
        {{ toConfirm.title }}
      </confirm>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <print/>
        </div>
        <div class="right">
          <template v-if="position.id > 0 && !editEnabled">
            <button
                v-if="position.status === 0"
                type="button"
                class="button"
                @click.prevent="toConfirm = {title : 'Przyjmujesz to zlecenie', handler : assign}"
            >
              przyjmij
            </button>
            <button
                v-if="position.status === 2"
                type="button"
                class="button button-danger"
                @click.prevent="toConfirm = {title : 'Odrzucasz to zlecenie, będzie dostępne dla innych pracowników', handler : cancel}"
            >
              odrzuć
            </button>
            <button
                v-if="position.status === 2"
                type="button"
                class="button"
                @click.prevent="toConfirm = {title : 'Zlecenie będzie oznaczone jako w toku', handler : progress}"
            >
              w toku
            </button>
            <button
                v-if="position.status === 8 || position.status === 2"
                type="button"
                class="button"
                @click.prevent="toConfirm = {title : 'Zlecenie wykonane w całości', date : true, handler : finish}"
            >
              wykonano
            </button>
            <button
                v-if="position.status === 8 || position.status === 2"
                type="button"
                class="button"
                @click.prevent="toConfirmPartial = finishPartially"
            >
              wykonano częściowo
            </button>
          </template>
          <button
              v-if="editEnabled || assigned"
              type="submit"
              class="button"
              @click.prevent="save()"
          >
            zapisz
            <i class="ico ico-save"/>
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button
              v-if="editEnabled && position.id"
              type="button"
              class="button"
              @click="duplicate"
          >
            powiel
            <i class="ico ico-add"/>
          </button>
          <button
              v-if="editEnabled && position.id"
              type="button"
              @click.prevent="remove = true"
              class="button button-danger">
            usuń
            <i class="ico ico-remove"/>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import InputWrapper from '../../util/Input'
import moment from 'moment'
import CropPicker from '../list/Crop'
import LabelPicker from '../list/Label'
import FertilizerPicker from '../list/Fertilizer'
import FieldUnit from '@/components/widget/FieldUnit'
import MixturePicker from '../list/PlantProtectionMixture'
import Unit from '@/components/widget/Unit'
import DateField from '@/components/util/Date'
import Print from '@/components/util/Print'
import { mapActions, mapGetters } from "vuex";
import ordersApi from "@/api/orders";
import tasksApi from "@/api/tasks";
import employeesApi from "@/api/employees";
import fieldsApi from "@/api/fields";
import vehiclesApi from "@/api/vehicles";
import machinesApi from "@/api/machines";

export default {
  props: { model: null, edit: false },
  components: {
    FieldUnit,
    DateField,
    Print,
    Unit,
    InputWrapper,
    CropPicker,
    LabelPicker,
    FertilizerPicker,
    MixturePicker
  },
  data() {
    let tmp = {
      enabled: true,

      createdAt: moment().format(),
      closedAt: null,

      scheduledCultivatedArea: null,
      realizedCultivatedArea: null,
      machineWidth: null,
      employerComments: '',
      comments: '',

      machine: null,
      vehicle: null,
      field: null,
      taskDefinition: null,
      crop: null,
      label: null,
      fertilizer: null,
      fertilizerQuantity: null,
      employer: null,
      plantProtectionQuantity: null,
      mixture: null,
    };
    tmp = { ...tmp, ...JSON.parse(JSON.stringify(this.model)) }
    return {
      position: tmp,
      errors: {},
      onDuplicate: null,
      remove: false,
      searchParams: {
        own: 'true',
        archive: 'false',
        import: 'true'
      },
      confirmDate: null,
      toConfirm: null,
      toConfirmPartial: null,
      partialValue: null,
      removeCultivation: false,
      select: null,
      taskDefinitions: [],
      dictionaries: {
        crops: [],
        labels: [],
        mixtures: [],
        fields: [],
        machines: [],
        vehicles: [],
        fertilizers: [],
        employers: [],
        taskDefinitions: []
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    filteredVehicles() {
      return this.dictionaries.vehicles
    },
    filteredMachines() {
      return this.dictionaries.machines.filter((m) => {
        if (m.taskDefinitions.length === 0 || !this.position.taskDefinition) {
          return true
        }
        if (this.position.machine && this.position.machine.id === m.id) {
          return true
        }

        return m.taskDefinition.find(t => t.id === this.position.taskDefinition.id) != null
      })
    },
    assigned() {
      return this.user.employer && this.position.employer && this.user.employer.id === this.position.employer.id;
    },
    editEnabled() {
      return this.hasRole('ROLE_MAIN') || (this.hasRole('ROLE_TASK_MANAGER') && this.position.status !== 256 && this.edit);
    }
  },
  watch: {
    'position.field': function (field) {
      if (field) {
        this.onFieldChanged(field);
      }
    },
    'position.machine': function (model) {
      if (model) {
        this.position.machineWidth = model.width;
      }
    },
    'position.label': function (model) {
      if (model) {
        this.position.labelNorm = model.norm;
        this.position.crop = model.crop
      }

    }
  },
  created() {
    if (this.editEnabled) {
      tasksApi.getTasks().then((resp) => {
        this.taskDefinitions = resp.data.collection
      });
      employeesApi.getEmployees().then((resp) => {
        this.dictionaries.employers = resp.data.collection
      });
      fieldsApi.getFields().then((resp) => {
        this.dictionaries.fields = resp.data.collection
      });
      vehiclesApi.getVehicles().then((resp) => {
        this.dictionaries.vehicles = resp.data.collection
      });
      machinesApi.getMachines().then((resp) => {
        this.dictionaries.machines = resp.data.collection
      });
    }
  },
  mounted() {
    if (this.$refs.first) {
      this.$bus.$emit('focus', this.$refs.first);
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    selectCrop(crop) {
      this.position.crop = crop
      this.select = null
      if (this.position.label && this.position.label.crop && this.position.label.crop.id !== this.position.crop.id) {
        this.position.label = null
      }
    },
    onFieldChanged(field) {
      this.position.scheduledCultivatedArea = field.cultivatedArea || field.cadastralArea
      if (field && field.currentCultivation) {
        this.position.label = field.currentCultivation.label
        this.position.crop = field.currentCultivation.crop
      }
    },

    isEnabled(name) {
      if (!this.position.taskDefinition) {
        return false;
      }

      return this.position.taskDefinition[name];
    },
    onRemove() {
      ordersApi.deleteOrder(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh')
        this.$emit('close')
        this.$emit('exit')
      });
    },
    onRemoveCultivation() {
      this.position.cultivations = this.position.cultivations.filter((row) => {
        return row !== this.removeCultivation
      })
      this.removeCultivation = false
    },
    save() {
      let promise = null;
      const payload = {
        ...this.position,
        cropId: this.position.crop?.id,
        labelId: this.position.label?.id,
        mixtureId: this.position.mixture?.id,
        taskDefinitionId: this.position.taskDefinition?.id,
        fieldId: this.position.field?.id,
        vehicleId: this.position.vehicle?.id,
        machineId: this.position.machine?.id,
        employeeId: this.position.employer?.id,
        fertilizerId: this.position.fertilizer?.id,
        issuedAt: this.position.createdAt,
      }
      delete payload.crop
      delete payload.label
      delete payload.mixture
      delete payload.taskDefinition
      delete payload.field
      delete payload.vehicle
      delete payload.machine
      delete payload.employer
      delete payload.fertilizer

      if (this.position.id) {
        promise = ordersApi.updateOrder(this.position.id, payload);
      } else {
        promise = ordersApi.createOrder(payload);
      }

      promise.then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        if (this.model) {
          this.$emit('saved', this.position);
        } else {
          this.$emit('created', this.position);
        }
        this.$emit('exit');
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })
        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      })
    },
    duplicate() {
      let cp = JSON.parse(JSON.stringify(this.position));
      this.onDuplicate = true
      cp.id = null
      cp.name = ''
      //cp.createdAt = moment().format()
      cp.closedAt = null
      cp.field = null
      cp.scheduledCultivatedArea = null
      cp.realizedCultivatedArea = null
      this.position = cp;
    },
    assign() {
      ordersApi.updateOrderAssignment(this.position.id, { assignment_action: "assign" })
          .then((resp) => {
            this.$notify({
              title: 'Przypisano pomyślnie',
              type: 'success'
            })
            this.position = { ...this.position, ...resp.data.data }
            this.$emit('refresh');
            this.toConfirm = null;
          });
    },
    progress() {
      ordersApi.partiallyCompleteOrder(this.position.id, {
        employerComments: this.position.employerComments,
      }).then((resp) => {
        this.$notify({
          title: 'Zadanie w toku',
          type: 'success'
        })
        this.position = { ...this.position, ...resp.data.data }
        this.$emit('refresh');
        this.toConfirm = null;
      });
    },
    cancel() {
      ordersApi.updateOrderAssignment(this.position.id, { assignment_action: "unassign" })
          .then((resp) => {
            this.$notify({
              title: 'Odrzucono pomyślnie',
              type: 'success'
            })
            this.position = { ...this.position, ...resp.data.data }
            this.$emit('refresh');
            this.toConfirm = null;
          });
    },
    finish() {
      ordersApi.completeOrder(this.position.id, {
        employerComments: this.position.employerComments,
        date: this.confirmDate
      }).then((resp) => {
        this.$notify({
          title: 'Zadanie zakończone',
          type: 'success'
        })
        this.position = { ...this.position, ...resp.data.data }
        this.$emit('refresh');
        this.$emit('exit');
        this.toConfirm = null;
      });
    },
    finishPartially() {
      this.partialValue = parseFloat(this.partialValue)
      this.position.scheduledCultivatedArea = parseFloat(this.position.scheduledCultivatedArea);
      if (this.partialValue < 0 || this.partialValue >= this.position.scheduledCultivatedArea) {
        this.toConfirm = null;
        this.$notify({
          title: 'Nieprawidłowa wartość',
          type: 'error'
        })
        return;
      }
      ordersApi.partiallyCompleteOrder(this.position.id, {
        employerComments: this.position.employerComments,
        value: this.partialValue,
        date: this.confirmDate
      }).then((resp) => {
        this.$notify({
          title: 'Wykonano pomyślnie',
          type: 'success'
        })
        this.position = { ...this.position, ...resp.data.data }
        this.$emit('refresh');
        this.$emit('exit');
        this.toConfirm = null;
        this.toConfirmPartially = null;
      });
    }
  },
}
</script>
<style lang="scss">
.vdp-datepicker {
  position: relative;

  .vdp-datepicker__clear-button {
    position: absolute;
    top: 3px;
    right: 5px;
  }
}
</style>
<style scoped lang="scss">
.grid .row > div {
  padding: 0 5px;
}

.grid.positions .row {
  padding: 0;
}

.flex-2 {
  width: 65% !important;
}

.flex-1 {
  width: 35% !important;
}

input[type=text], input[type=number], select {
  width: 100%;
  text-align: left !important;
}

.form-table .field-row-wide {
  display: block;

  > div {
    width: auto;

    label {
      width: 130px;

      .button {
        float: right;
        margin-right: 5px;
        margin-top: -5px;
      }
    }
  }

  .flex-1 label {
    width: 70px;
  }
}

.selector {
  display: flex;

  .desc {
    flex: 1;
    padding-top: 5px;
  }
}

.positions div {

  flex-shrink: 0;
}

.positions .quantity {
  min-width: 160px;
  flex: 1 !important;

  .simple-row > span:first-child {
    flex: 1;
  }

  .simple-row > span:last-child {
    width: 20px;
  }
}

.positions .name {
  flex: 2 !important;
}

.content {
  flex: 3 !important;
}

.row .content {
  font-size: 12px;
  line-height: 1.2em;
}

.form-group .unit {
  padding: 4px 0 4px 4px;
  display: block;
}

.form-group .unit2 {
  padding-top: 4px;
  display: block;
}

.small-number {
  max-width: 70px !important;
}

.center {
  text-align: center;
  display: block;
}

.form-table .field-row > div {
  flex: 1;
}

.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.min50 {
  flex: none !important;
  min-width: 50%;
  width: auto !important;
}

.qty {
  width: 170px !important;
  flex: none !important;
}

.qty label {
  width: 50px !important;
}

.num-left {
  text-align: left;
}

.num {
  > label {
    width: auto !important;
    margin-right: 10px;
  }
}

.small-number {
  text-align: left;
}

.comments {
  white-space: pre-wrap;
}

.readonly {
  display: block;
  padding-top: 5px;
}

.quantity-value {
  width: 150px !important;
}

.employer-form {
  label {
    font-size: 16px !important;
  }

  .form-control {
    font-size: 20px !important;
  }

  .quantity-value, .input-value {
    font-size: 18px;
  }

  .quantity-value {
    font-weight: bold !important;

  }

  .positions .row > div {
    font-size: 18px !important;
  }

  .button {
    font-size: 20px;
  }

  .small-number {
    flex: auto !important;
    width: auto !important;
    max-width: 150px !important;
    white-space: nowrap;
  }

  .field-row-wide .flex-1 label {
    width: 80px;
  }

  small.description {
    padding-left: 120px;
  }
}

.mx-datepicker {
  width: 120px;
}

.fertilizer-description-move {
  margin-left: -130px;
  padding-left: 130px !important;
}

small.description {
  display: block;
  position: relative;
  padding-top: 5px;
  font-size: 14px;
  padding-left: 90px;

  label {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
